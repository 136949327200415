import { graphql } from "gatsby";
import React from "react";
import Container from "../components/container/container";
import SEO from "../components/seo";
import ThumbGrid from "../components/thumb-grid/thumb-grid";
import PageTitle from "../components/page-title/page-title";
import Schedule from "components/schedule/schedule";

function AanbodPage({ data }) {
  const categories = data.allPrismicAanbodCategory.nodes.map((node) => {
    return {
      key: node.uid,
      title: node.data.title.text,
      link: `/aanbod/categorie/${node.uid}/`,
      image: node.data.image,
    };
  });

  return (
    <>
      <SEO title="Aanbod" />
      <PageTitle>Onze cursussen / consulten</PageTitle>
      <Container>
        <ThumbGrid items={categories}></ThumbGrid>
      </Container>
      <Schedule items={data.schedule.nodes}></Schedule>
    </>
  );
}

export const Query = graphql`
  {
    allPrismicAanbodCategory(sort: { fields: data___priority }) {
      nodes {
        _previewable
        uid
        data {
          title {
            text
          }
          image {
            url
            thumbnails {
              xs {
                url
              }
              xs_2x {
                url
              }
              xs_3x {
                url
              }
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    schedule: allPrismicAanbodItem {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          page_description
          category {
            slug
          }
          body1 {
            ... on PrismicAanbodItemDataBody1EnkeleSessie {
              slice_type
              primary {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
            ... on PrismicAanbodItemDataBody1MeerdereSessies {
              slice_type
              items {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
          }
        }
      }
    }
  }
`;

export default AanbodPage;
