import { Link } from "gatsby";
import React from "react";
import Image from "../image/image";
import * as styles from "./thumb-grid.module.scss";

function ThumbGrid({ items }) {
  return (
    <div className={styles.grid}>
      {items.map((item) => (
        <Link to={item.link} className={styles.gridItem} key={item.key}>
          <div className={styles.title}>
            <h2>{item.title}</h2>
          </div>
          {item.image.thumbnails && <Image source={item.image} alt={item.title}></Image>}
        </Link>
      ))}
    </div>
  );
}

export default ThumbGrid;
