import React from "react";
import Container from "../container/container";
import Image from "../image/image";
import * as styles from "./page-title.module.scss";

function PageTitle(props) {
  return (
    <div className={`${styles.title} bg-dark`}>
      {props.bg && (
        <Image source={props.bg} height="400" alt="header" className={styles.bg}></Image>
      )}
      <Container>
        <h1 className={styles.h1}>
          {props.children}
          {props.subtitle && <small>{props.subtitle}</small>}
        </h1>
      </Container>
    </div>
  );
}

export default PageTitle;
